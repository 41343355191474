import type {FC} from 'react';
import clsx from 'clsx';

import {Button} from '../button';
import {Link} from '../link';

export type NavLinkType = {
	type: 'navlink';
	title: string;
	href: string;
	targetBlank?: boolean;
	featured?: boolean;
};

type NavLinkProps = {
	link: NavLinkType;
	onClick?: () => void;
};

export const NavLink: FC<NavLinkProps> = ({link, onClick}) => {
	if (link.featured) {
		return (
			<Button asChild variant="secondary">
				<Link
					onClick={onClick}
					href={link.href}
					targetBlank={link.targetBlank}
					className={clsx('inline-block')}
				>
					{link.title}
				</Link>
			</Button>
		);
	}

	return (
		<Link
			onClick={onClick}
			href={link.href}
			targetBlank={link.targetBlank}
			className={clsx('inline-block', 'no-underline', 'font-bold')}
		>
			{link.title}
		</Link>
	);
};
